export default {
  addTask: 'Создать задачу RPA',
  updateTask: 'Изменить задачу RPA',
  bindBrowser: 'Привязать окно',
  workflowDashboard: 'Перейти к разработке рабочего процесса (с ядром 112)',
  taskConcurrent: 'Настройка параллельности задач',
  taskConcurrentTips: '*Здесь указывается, сколько окон одновременно будет открыто для выполнения этой задачи RPA',
  userManual: 'Руководство пользователя',
  taskName: 'Название задачи',
  workflowId: 'ID рабочего процесса',
  type: 'Тип',
  startTime: 'Время начала',
  endTime: 'Время окончания',
  remark: 'Комментарий',
  remarkPlaceholder: 'Введите комментарий для задачи RPA',
  taskNameRule: 'Введите название задачи',
  workflowRule: 'Введите ID рабочего процесса',
  browserConcurrent: 'Параллельность окон',
  browserConcurrentRule: 'Введите количество параллельных окон',
  frequency: 'Частота выполнения',
  frequencyRule: 'Введите частоту выполнения',
  specifiedTime: 'Задача по таймеру',
  timeInterval: 'Циклическая задача',
  immediately: 'Выполнить немедленно',
  day: 'день',
  hour: 'час',
  minute: 'минута',
  selected: 'выбрано',
  startTimeRule: 'Выберите время начала',
  startTimeRule2: 'Время начала должно быть позже текущего времени',
  endTimeRule: 'Выберите время окончания',
  endTimeRule2: 'Время окончания должно быть позже времени начала',
  deleteTaskText: 'Вы уверены, что хотите удалить задачу?',
  rpaStatus: 'Статус',
  running: 'Выполняется',
  pending: 'Ожидает выполнения',
  disabled: 'Отключено',
  finished: 'Завершено',
  concurrentPlaceholder: 'Число параллельных задач',
  concurrentValue: 'Параллельные задачи',
  concurrentRule1: 'Введите значение параллельных задач',
  runTask: 'Запустить задачу',
  stopTask: 'Остановить задачу',
  deleteTask: 'Удалить задачу',
  statusTooltip: 'Включить/отключить задачу RPA',
  disableTask:
    'Вы уверены, что хотите отключить задачу RPA?<p class="q-mt-sm text-red">После отключения задачи таймер не будет активироваться.</p>',
  enableTask: 'Вы уверены, что хотите включить задачу RPA?',
  logs: 'Журнал задачи',
  logsTime: 'Время выполнения',
  logContent: 'Результат выполнения',
  intervalPrepend: 'Каждые',
  intervalAppend: 'Выполнять один раз',
  concurrentTips: 'Ограничение параллельности задач. Установите в соответствии с конфигурацией компьютера!',
  bindError: 'Привяжите хотя бы одно окно',
  hasBind: 'Привязано:',
  bindNow: 'Привязать',
  logsType: 'Тип',
  openBrowser: 'Открыть окно',
  openSkip: 'Окно уже открыто, пропуск',
  openFailed: 'Ошибка открытия окна',
  closeBrowser: 'Закрыть окно',
  intNums: 'Введите целое число больше 0',
  skipTask: 'Задача выполняется, пропуск',
  enableRule: 'Пожалуйста, измените задачу RPA, убедитесь, что время начала позднее текущего времени!',
  timeout: 'Таймаут задачи',
  timeoutTips: '* Автоматическое закрытие окна, если превышено указанное время (если 0 - отключено)',
  timeoutRequired: 'Введите время таймаута задачи',
  timeoutNums: 'Введите число больше или равно 0',
  seconds: 'секунды',
  selectionTips:
    'Примечание: Связанные окна будут отображаться в верхней части списка, нажмите на значок стрелки для сортировки в обратном/ прямом порядке, а отмеченные связанные окна и не отмеченные связанные окна будут сортироваться отдельно.',
  appendPageData: 'Выбрать все на текущей странице',
  cancelPageData: 'Отменить выбор всего на текущей странице',
  cancelAll: 'Отменить выбор всего',
  taskSetting: 'Настройки задачи [параллельно {arg}]',
  taskSettingTitle: 'Настройки задачи',
  runningStrategy: 'Стратегия  работы окна',
  notOpened: 'Запретить выполнение задач RPA при открытых окнах',
  allCanRun: 'Разрешить выполнение задач RPA при открытых окнах',
  allCanRunTips: 'При выполнении задач RPA с открытыми окнами таймер "Время выполнения задачи" не будет активирован!',
  runAtOpened: 'Выполнять задачи при открытых окнах',
  success: 'Успешно',
  automaTips:
    'Обновление встроенного расширения automa запланировано на период со следующего понедельника по вторник. Мы настоятельно рекомендуем всем пользователям RPA экспортировать свои разработанные рабочие процессы в качестве резервной копии, чтобы избежать непредвиденных проблем.',
  specifiedCore: 'С ядром {version}'
}
